<template>
  <v-container>
    <base-material-card
      :title="$t('list', { name: $tc('user', 2) })"
      icon="mdi-account-multiple"
    >
      <v-row
        ><v-tabs
          v-if="!$vuetify.breakpoint.xsOnly"
          v-model="tab"
          right
          class="pl-0 pb-0"
          style="border-color: white; border-bottom: -2px; border-width: 1px"
        >
          <v-tab
            href="#notifications"
            class="pl-0 ml-0 pr-0"
            active-class="color"
            style="font-size: 14px"
            >{{ $tc("notification", 2) }}</v-tab
          >
          <v-tab href="#mails" active-class="color" style="font-size: 14px">{{
            $t("correo")
          }}</v-tab>
          <v-tab
            href="#whatsapp"
            active-class="color"
            style="font-size: 14px"
            >{{ $t("whatsapp") }}</v-tab
          >
        </v-tabs></v-row
      >
      <v-tabs-items
        v-model="tab"
        style="border-color: red; border-width: medium"
      >
        <v-tab-item value="notifications">
          <v-row justify="start">
            <v-col cols="6" md="2">
              <v-text-field
                outlined
                v-model="filters.search"
                dense
                :label="$t('search')"
                hide-details
                single-line
                @input="searchFilter"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-select
                v-model="filters.type"
                outlined
                dense
                :label="$t('type')"
                hide-details
                clearable
                single-line
                :items="types"
                @input="searchFilter"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2">
              <v-select
                outlined
                v-model="filters.state"
                dense
                :label="$t('state')"
                hide-details
                single-line
                :items="states"
                clearable
                @input="searchFilter"
              ></v-select>
            </v-col>
            <v-col cols="6" md="2">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    :value="
                      filters.date
                        ? $d(new Date(filters.date), 'date2digits')
                        : ''
                    "
                    :label="$tc('expenses.data')"
                    class="filters"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    @click:clear="
                      filters.date = null;
                      searchFilter();
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  no-title
                  v-model="filters.date"
                  @input="
                    menu = false;
                    searchFilter();
                  "
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col style="display: flex; align-items: center" cols="6" md="2">
              <v-btn
                outlined
                min-width="200px"
                dense
                @click="allMark()"
                class="filters"
                style="height: 30px; width: 100%"
                >{{ $t("notifications.read") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            :footer-props="{
              'items-per-page-text': $vuetify.breakpoint.smAndDown
                ? ''
                : $t('items_per_page'),
            }"
            :items="notifications"
            :server-items-length="total"
            :headers="headers"
            :item-class="TableClass"
            :options.sync="options"
            id="notifyList"
          >
            <template v-slot:item.type="{ item }">
              {{ $t(shortTraduccion(item)) }}
            </template>
            <template v-slot:item.notifications="{ item }">
              <v-menu v-if="$vuetify.breakpoint.xsOnly">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{
                      $t(traduccion(item), {
                        name: name(item),
                        tipo: tipo(item) != null ? tipo(item) : "",
                        time: time(item) != null ? time(item) : "",
                        change: change(item) != null ? change(item) : "",
                      })
                    }}
                  </span>
                </template>
                <p class="px-3 pt-3">
                  {{
                    $t(traduccion(item), {
                      name: name(item),
                      tipo: tipo(item) != null ? tipo(item) : "",
                      time: time(item) != null ? time(item) : "",
                      change: change(item) != null ? change(item) : "",
                    })
                  }}
                </p>
              </v-menu>
              <span v-else>{{
                $t(traduccion(item), {
                  name: name(item),
                  tipo: tipo(item) != null ? tipo(item) : "",
                  time: time(item) != null ? time(item) : "",
                  change: change(item) != null ? change(item) : "",
                })
              }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{
                $d(new Date(item.created_at.substring(0, 10)), "date2digits")
              }}
              - {{ +item.created_at.substring(11, 13) + 2
              }}{{ item.created_at.substring(13, 16) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row justify="center">
                <v-col :cols="item.read_at ? '12' : '4'" class="pa-0"
                  ><v-menu v-if="$vuetify.breakpoint.xsOnly" top>
                    <template v-slot:activator="{ on }">
                      <div style="display: inline" v-on="on">
                        <v-icon
                          small
                          :color="item.read_at ? 'white' : 'primary'"
                          >$eye</v-icon
                        >
                      </div>
                    </template>
                    <p class="px-3 pt-3">
                      {{
                        $t(traduccion(item), {
                          name: name(item),
                          tipo: tipo(item) != null ? tipo(item) : "",
                          time: time(item) != null ? time(item) : "",
                          change: change(item) != null ? change(item) : "",
                        })
                      }}
                    </p>
                  </v-menu>

                  <v-btn @click="goTo(item)" icon>
                    <v-icon
                      v-text="icon(item)"
                      small
                      :color="item.read_at ? 'white' : 'primary'"
                    /> </v-btn></v-col
                ><v-btn
                  @click="mark(item.id)"
                  icon
                  v-if="!item.read_at"
                  class="pa-0 ml-2 ml-sm-0"
                >
                  <v-icon small color="primary">mdi-check</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="mails">
          <MailList />
        </v-tab-item>
        <v-tab-item value="whatsapp">
          <WhatsappList />
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "UserNotificationsList",
  components: {
    MailList: () => import("@/components/mails/ListMails"),
    WhatsappList: () => import("@/components/mails/ListWhatsapp"),
  },

  mounted() {
    if (this.$route.params.solicitudes) {
      this.filters.type = "new_appointment";
      this.filters.state = "no_read";
    }
    this.fetchNotifications();
  },
  watch: {
    options: {
      handler() {
        this.fetchNotifications();
      },
      deep: true,
    },
  },
  data() {
    return {
      notifications: [],
      tab: "notifications",
      headers: [
        {
          text: "Tipo",
          sortable: false,
          value: "type",
        },
        {
          text: this.$tc("expenses.concepto"),
          sortable: false,
          value: "notifications",
        },
        { text: this.$t("day"), sortable: false, value: "created_at" },
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
        },
      ],
      filters: {},
      options: {},
      total: null,
      menu: false,
      types: [
        {
          text: this.$t("notifications.types.new_appointment"),
          sortable: false,
          value: "new_appointment",
        },
        {
          text: this.$t("notifications.types.new_invitation"),
          sortable: false,
          value: "new_invitation",
        },
        {
          text: this.$t("notifications.types.reminder"),
          sortable: false,
          value: "reminder",
        },

        {
          text: this.$t("notifications.types.pay"),
          sortable: false,
          value: "pay",
        },
      ],
      states: [
        {
          text: this.$t("notifications.states.read"),
          sortable: false,
          value: "read",
        },
        {
          text: this.$t("notifications.states.no_read"),
          sortable: false,
          value: "no_read",
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", [
      "getAllNotifications",
      "markNotification",
      "markAllNotification",
    ]),
    fetchNotifications() {
      this.getAllNotifications({
        filters: this.filters,
        pagination: this.options,
      }).then((data) => {
        this.notifications = data.data;
        console.log(this.notifications);
        this.total = data.total;
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchNotifications();
    }, 1000),
    type(item) {
      let type = item.type;
      type = type.split("\\");
      return type[type.length - 1];
    },
    toggleSelect() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.emails.map((x) => x[0].id);
      }
    },
    shortTraduccion(item) {
      let text =
        `notifications.types.` +
        this.shortCodeTraduccion(this.type(item), item);

      return text;
    },
    traduccion(item) {
      let text =
        `notifications.` + this.shortCodeTraduccion(this.type(item), item);
      switch (this.type(item)) {
        case "NewAppointmentNotification":
        case "NewPendingInscriptionUserNotification":
        case "NewPendingInscriptionNotification":
          if (!item.data.setting) {
            text = "notifications.new_appointment";
          } else {
            text = "notifications.new_appointment_" + item.data.setting;
          }

          break;
        case "AssignAppointmentBoxNotification":
          text = "notifications.reservation_box_appointment";
          break;
      }
      if (this.type !== "ReminderNotification")
        text += this.$store.getters["auth/isStudio"] ? "_studio" : "_tattooer";

      return text;
    },
    shortCodeTraduccion(type, item) {
      //console.log(item);
      switch (type) {
        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          return "reservation_box";
        case "NewAppointmentNotification":
        case "NewPendingInscriptionNotification":
          return "new_appointment";
        case "TypeArtistNotification":
          return "type_artist";
        case "TokenPaymentNotification":
          return "token_payment";
        case "ShareArchiveNotification":
          return "share_archive";
        case "DisableArtistNotification":
          return "disable_artist";
        case "PayedNotification":
          return "payed";
        case "PayedExtraNotification":
          return "payed_extra";
        case "CommissionsNotification":
          return "commissions";
        case "ReactiveTattooerNotification":
          return "reactive";
        case "NewDesignAddedNotification":
          return "add_design";
        case "DeleteSessionNotification":
          return "delete_session";
        case "NewSessionNotification":
          return "new_session";
        case "NewTattooerNotification":
        case "NewStudioNotification":
          return "new_invitation";
        case "PlanNotification":
          return "plan";
        case "ReminderNotification":
          return "reminder";
        case "EndingTrialNotification":
          return "ending_trial";
        case "NewAssistanceAppointmentNotification":
        case "AssaingTattooer":
          return "assistance_appointment";
        default:
          return type;
      }
    },
    name(item) {
      if (this.type(item) === "ReminderNotification") {
        return item.data.reminder.title || item.data.reminder.description;
      }
      //console.log(this.type(item));
      switch (this.type(item)) {
        case "NewAssistanceAppointmentNotification":
        case "AssaingTattooer":
        case "NewPendingInscriptionNotification":
        case "PayedExtraNotification":
        case "PayedNotification":
          return item.data.customer_name;
        case "TypeArtistNotification":
          return (
            item.data.tattooer_name.user.first_name +
            " " +
            item.data.tattooer_name.user.last_name
          );
        case "AssignAppointmentBoxNotification":
        case "NewAppointmentNotification":
          return item.data.customer_name;
        case "PlanNotification":
          if (
            item.data.change_plan === false &&
            item.data.studio_name.plan_id === 3
          )
            return "Ninguno";
          switch (item.data.plan_actual) {
            case 1:
              return "Bronze";
            case 2:
              return "Silver";
            case 3:
              return "Gold";
          }
        case "NewSessionNotification":
        case "DeleteSessionNotification":
          let last = item.data.date.customer.user.last_name;
          return item.data.date.customer.user.first_name + " " + last;

        default:
          return this.$store.getters["auth/isStudio"]
            ? item.data.tattooer_name
            : item.data.studio_name;
      }
    },
    time(item) {
      if (this.type(item) === "ReminderNotification") {
        return item.data.reminder.title || item.data.reminder.description;
      }
      //console.log(this.type(item), item);
      switch (this.type(item)) {
        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          return (
            this.$d(
              new Date(item.data.date.start_date.substring(0, 10)),
              "date2digits"
            ) +
            " - " +
            item.data.date.start_time
          );
        case "NewDesignAddedNotification":
        case "NewPendingInscriptionNotification":
        case "NewAppointmentNotification":
          if (item.data.setting == "week" || item.data.setting == "month") {
            return item.data.date.available_dates[0]
              ? this.$d(
                  new Date(
                    item.data.date.available_dates[0].date.substring(0, 10)
                  ),
                  "date2digits"
                )
              : "-";
          } else if (item.data.setting == "asap") {
            return "";
          } else {
            return item.data.date.available_dates[0]
              ? this.traductionTime(item.data.date.available_dates[0].date)
              : "-";
          }
        case "DeleteSessionNotification":
        case "NewSessionNotification":
          return this.traductionTime(
            item.data.date.available_dates[
              item.data.date.available_dates.length - 1
            ].date
          );

        default:
          return null;
      }
    },

    traductionTime(time) {
      let data = this.$d(new Date(time.substring(0, 10)), "date2digits");
      data += " - " + time.substring(11, 16);
      return data;
    },
    tipo(item) {
      if (this.type(item) === "ReminderNotification") {
        return item.data.reminder.title || item.data.reminder.description;
      }

      switch (this.type(item)) {
        case "TypeArtistNotification":
          //console.log(this.type(item), item.data.type_id.type_artist);
          let str = [];
          item.data.type_id.type_artist.forEach((element) => {
            str.push(this.$tc(element.name));
          });

          return str;
        default:
          return null;
      }
    },
    change(item) {
      if (this.type === "ReminderNotification") {
        return (
          this.item.data.reminder.title || this.item.data.reminder.description
        );
      }

      switch (this.type(item)) {
        case "PlanNotification":
          switch (item.data.studio_name.plan_id) {
            case 1:
              return "Bronze";
            case 2:
              return "Silver";
            case 3:
              return "Gold";
          }
        /*
          if (item.data.change_plan) {
            return item.data.studio_name.plan_id == 2 ? "Silver" : "Gold";
          } else {
            return item.data.studio_name.plan_id == 1 ? "Bronze" : "Silver";
          }
          */

        default:
          return null;
      }
    },
    icon(item) {
      switch (this.type(item)) {
        case "NewAppointmentNotification":
        case "NewDesignAddedNotification":
        case "NewSessionNotification":
        case "NewPendingInscriptionNotification":
          return "$calendar";
        case "NewTattooerNotification":
          return "$edit";

        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          return "$boxes";
        default:
          return "mdi-bell-outline";
      }
    },

    goTo(item) {
      console.log(item);
      if (this.type(item) !== "ReminderNotification")
        this.markNotification(item.id);
      switch (this.type(item)) {
        case "CommissionsNotification":
          this.$router.push({
            name: "economyView",
          });
          break;
        case "NewDesignAddedNotification":
        case "NewSessionNotification":
        case "DeleteSessionNotification":
        case "ShareArchiveNotification":
        case "NewAppointmentNotification":
        case "PayedNotification":
        case "PayedExtraNotification":
        case "NewPendingInscriptionNotification":
        case "NewAssistanceAppointmentNotification":
        case "AssaingTattooer":
          if (item.data.appointment_id)
            this.$router.push({
              name: "appointmentView",
              params: { id: item.data.appointment_id },
            });
          else
            this.$router.push({
              name: "customerView",
              params: { id: item.data.customer_id },
            });
          break;
        case "NewTattooerNotification":
          console.log(item);
          if (
            item.data.tattooer.tattooer_studios &&
            item.data.tattooer.tattooer_studios[0].status !== "confirmed"
          ) {
            this.$router.push({
              name: "accept_tattooer_invite",
              params: {
                id: item,
                studio: item.data.studio_id,
                hash: item.data.tattooer.tattooer_studios[0].hash,
                tattooer: this.$store.state.auth.user.id,
              },
            });
          } else {
            this.$router.push({
              name: "studioView",
              params: {
                id: item.data.studio_id,
              },
            });
          }
          break;
        case "AddReservationBoxNotification":
        case "AssignAppointmentBoxNotification":
          //console.log(item.data);
          this.$router.push({
            name: "boxList",
            params: {
              time: item.data.date.start_date,
            },
          });
          break;
        case "EndingTrialNotification":
        case "PlanNotification":
          //console.log(this.item.data);
          this.$router.push({
            name: "UserSettings",
            params: { type: "myplan" },
          });
          break;
        case "NewStudioNotification":
          this.$router.push({
            name: "existing_studio_invite",
            params: {
              studio: item.data.studio_id,
              tattooer: item.data.tattooer_id,
              hash: item.data.hash,
            },
          });
          break;
        case "ReminderNotification":
          this.$router.push({
            name: "economyView",
          });
      }
    },
    allMark() {
      this.$confirm(this.$t("markAllNotification"), "", "").then(() => {
        this.markAllNotification();
        this.fetchNotifications();
      });
    },
    mark(item) {
      this.$confirm(this.$t("markNotification"), "", "").then(() => {
        this.markNotification(item);
        this.fetchNotifications();
      });
    },
    TableClass(a) {
      if (!a.read_at) return "pending_not";
      else return "read";
    },
  },
};
</script>

<style lang="sass">
.pending_not
  font-weight: bolder
  color: var(--v-primary-base)

@media (min-width: 730px)
  #notifyList
    td:nth-child(4)
      min-width: 90px
      max-width: 90px
    td:nth-child(3)
      min-width: 130px
      max-width: 130px
@media (max-width: 800px)
  // Campos de la tabla
  #notifyList

    .v-data-table__mobile-row__header
      min-width: 100px !important
      text-align: left
    .v-data-table__mobile-row__cell
      width: 100%

  //Fin de los campos de la tabla
</style>
